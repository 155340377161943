import React from 'react'

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';

import BuroCardBroken from '../components/pages/BuroCardBroken';
import GlobalFooterProvider from '../context/GlobalStateFooterContext';

export class BuroPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
        <GlobalFooterProvider>
            <LayoutsComponent
                broker={this.props.broker}
                layout={this.props.pageContext.layout}
                location={this.props.location}
                pageContext={this.props.pageContext}
                companyName={this.props.pageContext.company}
                gradient currentStep={this.props.pageContext.step}
                render={() => (
                    <>
                        <SEO title={this.props.pageContext.title} />
                        <BuroCardBroken pageContext={this.props.pageContext} />
                    </>
                )}
            />
        </GlobalFooterProvider>
        )
    }
}


export default BuroPage
